<template>
  <b-card no-body>
    <b-card-body>
      <div class="h4 mb-1">
        พนักงาน
      </div>

      <div class="d-flex flex-wrap">
        <div class="d-flex align-items-start py-50">
          <div class="btn-disable">
            ประเภท
          </div>
          <b-form-select
            v-model="search_type"
            :options="optionStaff"
            placeholder="เลือกประเภทที่จะค้นหา"
          />
        </div>

        <div class="d-flex align-items-start py-50">
          <div class="btn-disable">
            คำค้นหา
          </div>
          <input
            id="username"
            v-model="search_val"
            :disabled="!search_type"
            type="text"
            class="form-control"
            placeholder="กรอกข้อมูลที่ต้องการค้นหา"
          >
        </div>

        <div class="d-flex align-items-start py-50">
          <div class="btn-disable">
            ROLE
          </div>
          <b-form-select
            v-model="statusStaff"
            :options="rolelist"
          />
        </div>

        <div class="d-flex align-items-start py-50">
          <button
            class="btn btn-primary ml-lg-25"
            @click="getUsers()"
          >
            ค้นหา
          </button>
        </div>
      </div>

      <!-- <div class="row no-gutters">
        <div class="d-flex">
          <b-form-select
            v-model="statusStaff"
            :options="rolelist"
          />
        </div>

        <button
          class="btn btn-primary ml-50"
          @click="getUsers()"
        >
          ค้นหา
        </button>
      </div> -->
      <div class="d-flex justify-content-between mt-3">
        <div class="d-flex">
          <b-button
            v-if="UserData.vip === 1 || permisedit == true"
            v-b-modal.modal-1
            variant="primary"
            class="mr-50"
            @click="addData"
          >
            เพิ่มแอดมิน
          </b-button>

          <b-button
            v-if="UserData.vip === 1 || permisedit == true"
            v-b-modal.modal-role
            variant="success"
            @click="addData"
          >
            ROLE
          </b-button>
        </div>
        <!-- <b-form-group
          label="ค้นหา"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="md"
          label-for="filterInput"
          class="mb-0"
        >
          <template #label="{label}">
            <span>{{ label }}</span>
            <span class="text-warning">ค้นหา</span>
          </template>
<b-form-input id="filterInput" v-model="filter" type="search" />
</b-form-group> -->
      </div>
    </b-card-body>
    <b-table
      striped
      hover
      responsive
      class="position-relative items-center"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell(index)="data">
        {{ perPage * (currentPage - 1) + (data.index + 1) }}
      </template>
      <template #cell(picture)="data">
        <b-avatar
          size="lg"
          :src="data.item.picture"
        />
      </template>
      <template #cell(ip)="data">
        <div class="text-nowrap">
          <a
            :href="`http://${data.item.ip}`"
            target="_blank"
          >{{ data.item.ip }}</a>

        </div>
      </template>
      <!-- <template #cell(roleshow)="data">
        <p v-if="data.item.role === 2">
          super admin
        </p>
        <p v-if="data.item.role === 3">
          super admin
        </p>
        <p v-if="data.item.role === 4">
          theme admin
        </p>
        <p v-if="data.item.role === 5">
          marketing
        </p>
        <p v-if="data.item.role === null">
          admin
        </p>
      </template> -->

      <template #cell(time)="data">
        <p v-if="data.item.admintime">
          {{ data.item.admintime }} น.
        </p>
        <p v-else>
          ยังไม่ได้ตั้งเวลาทำงาน
        </p>
      </template>

      <template #cell(status)="data">
        <span
          v-if="data.item.online === 0"
          class="badge badge-glow badge-success"
        > Online</span>

        <span
          v-else
          class="badge badge-glow badge-danger"
        > Offline </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap d-flex align-items-center">
          <!-- <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'dashboard', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          /> -->
          <i
            v-if="data.item.online === 0 && (permisedit == true || UserData.vip === 1)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.top="'Kick Off'"
            class="far fa-power-off mr-1"
            @click="closeItem(data.item.id)"
          />

          <feather-icon
            v-if="UserData.vip === 1 || permisedit == true"
            v-b-modal.modal-1
            icon="EditIcon"
            size="16"
            class="mr-1"
            @click="editData(data.item)"
          />
          <feather-icon
            v-if="UserData.vip === 1 || permisedit == true"
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            @click="deleteData(data.item.id)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions2)="data">
        <div class="text-nowrap d-flex align-items-center">
          <feather-icon
            v-if="permisedit == true"
            :id="`invoice-row-${data.item.id}-preview-icon`"
            v-b-modal.modal-1
            icon="EditIcon"
            size="16"
            class="mr-1"
            @click="editData(data.item)"
          />
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <b-modal
      id="modal-1"
      title="แอดมิน"
      ok-title="Submit"
      @ok="submit"
    >
      <b-form-group
        label="ชื่อ"
        label-for="vi-first-name"
      >
        <b-form-input
          id="vi-first-name"
          v-model="name"
          placeholder=""
        />

      </b-form-group>
      <b-form-group
        label="เบอร์โทร"
        label-for="vi-tel"
      >

        <b-form-input
          id="vi-tel"
          v-model="tel"
          placeholder=""
        />

      </b-form-group>
      <b-form-group
        label="username"
        label-for="vi-username"
      >

        <b-form-input
          id="vi-username"
          v-model="username"
          placeholder=""
        />

      </b-form-group>

      <b-form-group
        label="Password"
        label-for="vi-password"
      >
        <b-form-input
          id="vi-password"
          v-model="password"
          placeholder=""
          type="password"
        />
      </b-form-group>

      <b-form-group
        label="Role"
        label-for="vi-role"
      >
        <v-select
          v-model="role"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="itemsRole"
          :reduce="name => name._id"
        />
      </b-form-group>

      <!-- <b-form-group
        label="Line ID"
        label-for="vi-lineuserid"
      >

        <b-form-input
          id="vi-lineuserid"
          v-model="lineuserid"
          placeholder=""
        />

      </b-form-group> -->

      <div>
        <h5>รูป</h5>
        <div class="row px-1">
          <div
            class="col-4 cursor-pointer rounded-lg"
            :class="{ 'bg-primary': avatar === '01.webp' }"
            @click.prevent="selectAvatar('01.webp')"
          >
            <img
              class="w-100"
              src="/images/admin/01.webp"
            >
          </div>
          <div
            class="col-4 cursor-pointer rounded-lg"
            :class="{ 'bg-primary': avatar === '02.webp' }"
            @click.prevent="selectAvatar('02.webp')"
          >
            <img
              class="w-100"
              src="/images/admin/02.webp"
            >
          </div>
          <div
            class="col-4 cursor-pointer rounded-lg"
            :class="{ 'bg-primary': avatar === '03.webp' }"
            @click.prevent="selectAvatar('03.webp')"
          >
            <img
              class="w-100"
              src="/images/admin/03.webp"
            >
          </div>
          <div
            class="col-4 cursor-pointer rounded-lg"
            :class="{ 'bg-primary': avatar === '04.webp' }"
            @click.prevent="selectAvatar('04.webp')"
          >
            <img
              class="w-100"
              src="/images/admin/04.webp"
            >
          </div>
          <div
            class="col-4 cursor-pointer rounded-lg"
            :class="{ 'bg-primary': avatar === '05.webp' }"
            @click.prevent="selectAvatar('05.webp')"
          >
            <img
              class="w-100"
              src="/images/admin/05.webp"
            >
          </div>
          <div
            class="col-4 cursor-pointer rounded-lg"
            :class="{ 'bg-primary': avatar === '06.webp' }"
            @click.prevent="selectAvatar('06.webp')"
          >
            <img
              class="w-100"
              src="/images/admin/06.webp"
            >
          </div>
        </div>
      </div>
      <!-- {{ starttime }} -->
      <!-- <b-form-group
        label="เวลาเริ่มงาน"
        label-for="vi-first-name"
      >
        <b-form-timepicker
          id="timepicker-placeholder"
          v-model="starttime"
          placeholder="Choose a time"
          local="th"
        />
      </b-form-group>
      <b-form-group
        label="เวลาเริ่มเลิกงาน"
        label-for="vi-first-name"
      >
        <b-form-timepicker
          id="timepicker-placeholder"
          v-model="endtime"
          placeholder="Choose a time"
          local="th"
        />
      </b-form-group> -->
    </b-modal>

    <b-modal
      id="modal-role"
      title="Role"
      hide-footer
      size="lg"
    >
      <div class="pb-0 d-flex align-start">
        <div class="row no-gutters">
          <div class="row">
            <div class="d-flex col col-12">
              <div class="d-flex align-items-start">
                <div class="btn-disable">
                  ชื่อ ROLE
                </div>
              </div>
              <input
                v-model="rolename"
                type="text"
                class="form-control"
              >
            </div>
          </div>
        </div>

        <div
          class="row no-gutters"
          style="justify-content: end"
        >
          <button
            class="btn btn-primary"
            @click="addrole()"
          >
            สร้าง
          </button>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        class="position-relative items-center mt-2"
        :items="itemsRole"
        :fields="fieldRole"
      >
        <template #cell(permission)="data">
          <button
            class="btn btn-warning"
            @click="permissModal(data.item)"
          >
            Permission
          </button>
        </template>

        <template #cell(delete)="data">
          <button
            class="btn btn-outline-danger text-danger"
            @click="deleteRole(data.item._id)"
          >
            <i class="fas fa-trash mr-50" /> ลบ
          </button>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      ref="modal-permission"
      title="Permission"
      scrollable
      hide-footer
    >
      <b-table
        striped
        hover
        responsive
        class="position-relative items-center mt-2"
        :items="itemsPermission"
        :fields="visiblePermission"
      >
        <template #cell(permission)="data">
          <b-form-checkbox
            v-model="data.item.permission"
            class="custom-control-primary"
            @change="changePermission(itemsPermission)"
          />
        </template>

        <template #cell(permission_edit)="data">
          <b-form-checkbox
            v-if="data.item.id === 'admin' || data.item.id === 'member' || data.item.id === 'coupon' || data.item.id === 'promotion' || data.item.id === 'updategame' || data.item.id === 'wheelsetting' || data.item.id === 'dailysetting' || data.item.id === 'settingbank' || data.item.id === 'setting'"
            v-model="data.item.permission_edit"
            class="custom-control-primary"
            @change="changePermission(itemsPermission)"
          />
        </template>
      </b-table>
    </b-modal>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  BFormCheckbox, BTooltip, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  BFormInput, BButton, BCardBody, VBToggle, VBTooltip,
  // BInputGroupAppend,
  // BFormTimepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormCheckbox,
    // BFormTimepicker,
    // flatPickr,
    vSelect,
    BTooltip,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      rolename: '',
      roleId: '',
      statusStaff: null,
      optionStaff: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'ได้รับยูสเซอร์แล้ว' },
        { value: 2, text: 'ยังไม่ได้รับยูสเซอร์แล้ว' },
      ],
      fieldPermission: [
        { key: 'permission', label: '', show: true },
        { key: 'path', label: 'ชื่อ Path', show: true },
        { key: 'permission_edit', label: 'สิทธิแก้ไข', show: true },
      ],
      itemsPermission: [
        {
          id: 'dashboard', permission: false, path: 'dashboard', permission_edit: false, pathshow: '/dashboard',
        },
        {
          id: 'waitingdep', permission: false, path: 'รายการฝากไม่สำเร็จ', permission_edit: false, pathshow: '/waiting-dep',
        },
        {
          id: 'deposit', permission: false, path: 'รายการฝากออโต้', permission_edit: false, pathshow: '/Deposit',
        },
        {
          id: 'depositadmin', permission: false, path: 'รายการฝากโดยแอดมิน', permission_edit: false, pathshow: '/Deposit-FromAdmin',
        },
        {
          id: 'predepositkr', permission: false, path: 'รายการแจ้งฝากเกาหลี', permission_edit: false, pathshow: '/Pre-Deposit-kr',
        },
        {
          id: 'waitdepositkr', permission: false, path: 'stetement เกาหลี', permission_edit: false, pathshow: '/Wait-Deposit-kr',
        },
        {
          id: 'withdraw', permission: false, path: 'รายการถอน', permission_edit: false, pathshow: '/Withdraw',
        },
        {
          id: 'withdrawkr', permission: false, path: 'รายการถอน (เกาหลี)', permission_edit: false, pathshow: '/Withdraw-kr',
        },
        {
          id: 'memberfirstdep', permission: false, path: 'สมาชิกใหม่ฝากครั้งแรก', permission_edit: false, pathshow: '/Member-firstdep',
        },
        {
          id: 'nodepositmembers', permission: false, path: 'สมาชิกใหม่ที่ยังไม่ฝาก', permission_edit: false, pathshow: '/no-deposit-members',
        },
        {
          id: 'adminfirstdepcheck', permission: false, path: 'รายงานปิดยอดแอดมิน', permission_edit: false, pathshow: '/admin-firstdep-check',
        },
        {
          id: 'invitefriendold', permission: false, path: 'แนะนำ (เซียน)', permission_edit: false, pathshow: '/invite-friend-old',
        },
        {
          id: 'invitefriend', permission: false, path: 'พันธมิตร', permission_edit: false, pathshow: '/invite-friend',
        },
        {
          id: 'calling', permission: false, path: 'ติดตามลูกค้า', permission_edit: false, pathshow: '/calling',
        },
        {
          id: 'admin', permission: false, path: 'พนักงาน', permission_edit: false, pathshow: '/admin-management',
        },
        {
          id: 'member', permission: false, path: 'สมาชิก', permission_edit: false, pathshow: '/Member',
        },
        {
          id: 'userlastplay', permission: false, path: 'สมาชิกเล่นล่าสุด', permission_edit: false, pathshow: '/userLastPlay',
        },
        {
          id: 'ref', permission: false, path: 'แนะนำเพื่อน', permission_edit: false, pathshow: '/ref',
        },
        {
          id: 'cashback', permission: false, path: 'โบนัสคืนยอด', permission_edit: false, pathshow: '/bonus/cashback',
        },
        {
          id: 'depresend', permission: false, path: 'ประวัติการฝากเงินซ้ำ', permission_edit: false, pathshow: '/Deposit-Resend',
        },
        {
          id: 'usereditlog', permission: false, path: 'ประวัติแก้ไขข้อมูลสมาชิก', permission_edit: false, pathshow: '/history/UserEditlog',
        },
        {
          id: 'loguser', permission: false, path: 'ประวัติใช้งานสมาชิก', permission_edit: false, pathshow: '/loguser',
        },
        {
          id: 'sbobetwinlose', permission: false, path: 'รายงาน win/lose', permission_edit: false, pathshow: '/sbobet-win-lose',
        },
        {
          id: 'coupon', permission: false, path: 'สร้างคูปอง', permission_edit: false, pathshow: '/system/coupon',
        },
        {
          id: 'outstandingadmin', permission: false, path: 'Admin ดีเด่น', permission_edit: false, pathshow: '/outstanding-admin',
        },
        {
          id: 'dashboarddetail', permission: false, path: 'ข้อมูลธุรกรรม', permission_edit: false, pathshow: '/dashboard-detail',
        },
        {
          id: 'reportdep', permission: false, path: 'รายงานฝาก', permission_edit: false, pathshow: '/reports/deposits',
        },
        {
          id: 'reportwith', permission: false, path: 'รายงานถอน', permission_edit: false, pathshow: '/reports/withdraws',
        },
        {
          id: 'reportdepwit', permission: false, path: 'สรุปรายการฝากถอน', permission_edit: false, pathshow: '/reports/depwit',
        },
        {
          id: 'logdepwith', permission: false, path: 'log ฝากถอน', permission_edit: false, pathshow: '/logs/deposit-withdraw',
        },
        {
          id: 'logbank', permission: false, path: 'รายงานธนาคาร', permission_edit: false, pathshow: '/logs/banks',
        },
        {
          id: 'promotion', permission: false, path: 'โปรโมชั่น', permission_edit: false, pathshow: '/promotion',
        },
        {
          id: 'announcements', permission: false, path: 'ประกาศ', permission_edit: false, pathshow: '/announcements',
        },
        {
          id: 'ranking', permission: false, path: 'อันดับ', permission_edit: false, pathshow: '/system/ranking',
        },
        {
          id: 'updategame', permission: false, path: 'อัปเดทเกมส์', permission_edit: false, pathshow: '/update-product',
        },
        {
          id: 'eventssoccer', permission: false, path: 'ทายผลฟุตบอล', permission_edit: false, pathshow: '/events/soccer-predictions',
        },
        {
          id: 'eventslotto', permission: false, path: 'ทายผลหวย', permission_edit: false, pathshow: '/events/lotto-predictions',
        },
        {
          id: 'eventsreward', permission: false, path: 'กิจกรรมแลกของรางวัล', permission_edit: false, pathshow: '/events/reward',
        },
        {
          id: 'wheelsetting', permission: false, path: 'ตั้งค่ากงล้อ', permission_edit: false, pathshow: '/wheel/setting',
        },
        {
          id: 'wheelhistory', permission: false, path: 'รายการเล่นกงล้อ', permission_edit: false, pathshow: '/wheel/history',
        },
        {
          id: 'dailysetting', permission: false, path: 'ตั้งค่ารางวัล daily login', permission_edit: false, pathshow: '/dailylogin/setting',
        },
        {
          id: 'dailyquestssetting', permission: false, path: 'ตั้งค่ารางวัล daily quests', permission_edit: false, pathshow: '/dailyquests/setting',
        },
        {
          id: 'bank', permission: false, path: 'ลิสธนาคาร', permission_edit: false, pathshow: '/bank',
        },
        {
          id: 'settingbank', permission: false, path: 'ตั้งค่าธนาคาร', permission_edit: false, pathshow: '/setting-bank',
        },
        {
          id: 'channel', permission: false, path: 'ช่องทางที่รู้จัก', permission_edit: false, pathshow: '/channel',
        },
        {
          id: 'logadmin', permission: false, path: 'log การเข้าใช้งานแอดมิน', permission_edit: false, pathshow: '/log-admin',
        },
        {
          id: 'logwithdraw', permission: false, path: 'log อนุมัติถอน', permission_edit: false, pathshow: '/log-withdraw',
        },
        {
          id: 'setting', permission: false, path: 'จัดการเอเย่นต์', permission_edit: false, pathshow: '/setting',
        },
        {
          id: 'aghistorylog', permission: false, path: 'log หน้าจัดการเอเย่นต์', permission_edit: false, pathshow: '/history/log',
        },
        {
          id: 'autowithscb', permission: false, path: 'ระบบถอนออโต้ scb', permission_edit: false, pathshow: '/auto-withdraw-scb',
        },
        {
          id: 'redepadmin', permission: false, path: 'รายการคืนเงิน', permission_edit: false, pathshow: '/Deposit-Admin',
        },
        {
          id: 'smsandstatement', permission: false, path: 'sms & statement', permission_edit: false, pathshow: '/sms-deposit',
        },
      ],
      fieldRole: [
        { key: 'name', label: 'ชื่อ Role', show: true },
        { key: 'permission', label: 'Permission', show: true },
        { key: 'delete', label: 'ลบ', show: true },
      ],
      depositdata: [],
      rolelist: [
        { text: 'super admin', value: 2 },
        { text: 'admin', value: null },
        // { text: 'sbo', value: 3 },
        // { text: 'theme admin', value: 4 },
        // { text: 'marketing', value: 5 },
      ],
      itemsRole: [
        { id: 1, role: 'admin' },
        { id: 1, role: 'admin' },
        { id: 1, role: 'admin' },
      ],
      search_type: null,
      search_val: null,
      starttime: '',
      endtime: '',
      role: null,
      adminData: '',
      name: '',
      tel: '',
      username: '',
      lineuserid: '',
      password: '',
      avatar: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.', show: true },
        { key: 'username', label: 'username', show: true },
        { key: 'name', label: 'ชื่อแอดมิน', show: true },
        { key: 'tel', label: 'เบอร์โทร', show: true },
        { key: 'rolename', label: 'role', show: true },
        // { key: 'time', label: 'เวลาทำงาน', show: true },
        { key: 'status', label: 'status', show: true },
        { key: 'actions', label: 'Action', show: true },
        // { key: 'actions2', label: 'Action', show: true },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      permisedit: false,
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleFieldsRole() {
      return this.fieldRole.filter(fieldss => {
        if (fieldss.key === 'delete') {
          return false
        }
        return fieldss.show
      })
    },
    visibleFields() {
      return this.fields.filter(field => {
        if (field.key === 'actions' && this.UserData.vip !== 1) {
          return false
        }
        if (field.key === 'actions2' && this.UserData.vip === 1 && this.UserData.hide === 1) {
          return false
        }
        return field.show
      })
    },
    visiblePermission() {
      return this.fieldPermission.filter(field => field.show)
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getAdmin()
    this.Rolelist()
    this.permisedit = this.UserData.ability[this.UserData.ability.findIndex(x => x.path === this.$route.path)].edit
  },
  methods: {
    Rolelist() {
      this.$http
        .get('/role/list')
        .then(response => {
          this.itemsRole = response.data
        })
        .catch(error => console.log(error))
    },
    addrole() {
      // console.log(item)
      const obj = {
        name: this.rolename,
      }
      this.$http
        .post('/role/store', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.rolename = ''
          this.Rolelist()
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => console.log(error))
    },
    changePermission(item) {
      // console.log(item)
      const obj = {
        id: this.roleId,
        role: item,
      }
      this.$http
        .post('/role/permission', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          // this.$swal({
          //   icon: 'success',
          //   title: 'Success',
          //   text: response.data.message,
          //   customClass: {
          //     confirmButton: 'btn btn-success',
          //   },
          // })
        })
        .catch(error => console.log(error))
    },
    deleteRole(id) {
      this.$swal({
        title: 'ลบ role ?',
        text: 'กรุณายืนยันการลบ role',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            id,
          }
          this.$http
            .post('/role/delete', obj)
            .then(response => {
              this.Rolelist()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: '<h3 style="color: #141414">ลบข้อมูลสำเร็จ</h3>',
                  text: '',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.$swal('ทำรายการไม่สำเร็จ', error.response.data.message, 'error')
            })
        } else {
          this.$swal('ยกเลิก', '', 'error')
        }
      })
    },
    // ----------------------------------------------------------------
    // eslint-disable-next-line no-unused-vars
    permissModal(val) {
      // console.log(val)
      if (val.role.length > 0) {
        this.itemsPermission = val.role
      }
      // eslint-disable-next-line no-underscore-dangle
      this.roleId = val._id
      this.$refs['modal-permission'].show()
    },
    closeItem(val) {
      const formData = {
        id: val,
      }
      this.$http
        .post('/CheckOut/UserAdmin', formData)
        .then(response => {
          // console.log(response.data)
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => console.log(error))
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getAdmin() {
      this.$http
        .get('/agent')
        .then(response => {
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    submit() {
      const time = null
      const formData = {
        admintime: time,
        name: this.name,
        tel: this.tel,
        username: this.username,
        password: this.password,
        avatar: this.avatar,
        role: this.role,
        lineuserid: this.lineuserid,
        id: this.adminData ? this.adminData.id : null,
      }
      if (this.adminData && this.adminData.id) {
        this.$http
          .post('/agent/updateV2', formData)
          .then(() => {
            this.getAdmin()
            this.starttime = ''
            this.endtime = ''
            this.name = ''
            this.tel = ''
            this.username = ''
            this.password = ''
            this.lineuserid = ''
            this.avatar = ''
            this.role = null
            this.Success('update สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('/agent/store', formData)
          .then(() => {
            this.getAdmin()
            this.starttime = ''
            this.endtime = ''
            this.name = ''
            this.tel = ''
            this.username = ''
            this.lineuserid = ''
            this.password = ''
            this.avatar = ''
            this.role = null
            this.Success('เพิ่มแอดมินสำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
      // if (this.role !== 5 && this.UserData.role === 'agadmin' && (!this.starttime || !this.endtime)) {
      //   this.Warnings('กรุณาเลือกเวลาเข้างานและเลิกงาน')
      // } else {

      // }
    },
    deleteData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .get(`/agent/delete/${id}`)
            .then(response => {
              this.getAdmin()
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your file has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('Cancelled', 'not been deleted !', 'error')
        }
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      this.adminData = null
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.starttime = ''
      this.endtime = ''
      this.name = ''
      this.tel = ''
      this.lineuserid = ''
      this.username = ''
      this.password = ''
      this.avatar = ''
      this.role = ''
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // console.log(data)
      // if (data.admintime) {
      //   const time = data.admintime.split('-')
      //   // console.log(time)
      //   // eslint-disable-next-line prefer-destructuring
      //   this.starttime = time ? time[0] : ''
      //   // eslint-disable-next-line prefer-destructuring
      //   this.endtime = time ? time[1] : ''
      // } else {
      //   this.starttime = ''
      //   this.endtime = ''
      // }

      this.adminData = data
      this.name = data.name
      this.tel = data.tel
      this.role = data.role_profile
      this.username = data.username
      this.avatar = data.avatar
      this.lineuserid = data.lineuserid
      this.password = ''
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">แจ้งเตือน !</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Warnings(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color: #141414">แจ้งเตือน</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    selectAvatar(val) {
      this.avatar = val
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
<style lang="scss">
.btn {
  box-shadow: 0 4px 8px -4px rgba(94, 86, 105, .42) !important;
}

.row {
  flex-wrap: wrap;
  flex: 1 1 auto;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
