<template>
  <b-row>
    <b-col md="12">
      <b-card
        no-body
        class="mt-2"
      >
        <div>
          <div class="wrap-table100">
            <div class="table100 ver1">
              <div class="table100-firstcol">
                <table>
                  <thead>
                    <tr class="row100 head">
                      <th class="cell100 column1">
                        รายละเอียด/วันที่
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        สมัครวันนี้
                      </td>
                    </tr>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        สมัครและเติมวันนี้
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        เติมเงินครั้งแรก (จำนวนคน)
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        เติมเงินครั้งแรก (ยอดเงิน)
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        เติมเงินครั้งแรกเฉพาะเซียน (จำนวนคน)
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        เติมเงินครั้งแรกเฉพาะเซียน (ยอดเงิน)
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        จำนวนฝากวันนี้
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        Active วันนี้
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        ยอดฝาก
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        ยอดถอน
                      </td>
                    </tr>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        ยอดรวม
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        โบนัส
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        Facebook
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        Google
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        Youtube
                      </td>
                    </tr>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        TikTok
                      </td>
                    </tr>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        SMS
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        เพื่อนแนะนำ
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="wrap-table100-nextcols js-pscroll">
                <div class="table100-nextcols">
                  <table>
                    <thead>
                      <tr class="row100 head">
                        <th
                          v-for="day in endDateTable"
                          :key="day"
                          class="cell100 column2"
                        >
                          {{ day }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.regis"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.regisdep"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.depfirst_count"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.depfirst_sum"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.depfirst_byzean_count"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.depfirst_byzean_sum"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.nodeposit"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>

                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.active"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>

                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.deposit"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.withdraw"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.total"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.bonus"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.facebook"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.google"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.youtube"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.tiktok"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.sms"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                      <tr class="row100 body">
                        <td
                          v-for="item in monthly.friend"
                          :key="item"
                          class="cell100 column2"
                        >
                          {{ Commas(item) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="table100-lastcol">
                <table>
                  <thead>
                    <tr class="row100 head">
                      <th class="cell100 column1">
                        รวม
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{
                          monthly.regis ?
                            Commas(
                              monthly.regis.reduce(
                                (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                0,
                              ),
                            ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.regisdep ?
                          Commas(
                            monthly.regisdep.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.depfirst_count ?
                          Commas(
                            monthly.depfirst_count.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.depfirst_sum ?
                          Commas(
                            monthly.depfirst_sum.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        บาท
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.depfirst_byzean_count ?
                          Commas(
                            monthly.depfirst_byzean_count.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.depfirst_byzean_sum ?
                          Commas(
                            monthly.depfirst_byzean_sum.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        บาท
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.nodeposit ?
                          Commas(
                            monthly.nodeposit.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        รายการ
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.active ?
                          Commas(
                            monthly.active.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.deposit ?
                          Commas(
                            monthly.deposit.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        บาท
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.withdraw ?
                          Commas(
                            monthly.withdraw.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        บาท
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.total ?
                          Commas(
                            monthly.total.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            )
                          ) : 0
                        }}
                        บาท
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.bonus ?
                          Commas(
                            monthly.bonus.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        บาท
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.facebook ?
                          Commas(
                            monthly.facebook.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.google ?
                          Commas(
                            monthly.google.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.youtube ?
                          Commas(
                            monthly.youtube.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.tiktok ?
                          Commas(
                            monthly.tiktok.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>
                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.sms ?
                          Commas(
                            monthly.sms.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>

                    <tr class="row100 body">
                      <td class="cell100 column1">
                        {{ monthly.friend ?
                          Commas(
                            monthly.friend.reduce(
                              (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                              0,
                            ),
                          ) : 0
                        }}
                        คน
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
}
</script>

<style lang="scss" scoped>

</style>
