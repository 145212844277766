<template>
  <div>
    <b-card
      class="card-transaction h-100"
      style="border-radius: 15px;"
      no-body
    >
      <b-card-header>
        <b-card-title>รายการฝากสูงสุด 5 อันดับ (เกาหลี)</b-card-title>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          class="cursor-pointer"
        />
      </b-card-header>

      <b-card-body>
        <div
          v-for="(item, _id) in data.DepTop5KR"
          :key="_id"
          class="rank_list mb-1"
        >
          <img
            src="/rank/5.webp"
            alt="rank"
            height="50"
          >

          <div class="rank_detail ml-1">
            <h3 class="mb-0">
              {{ item.username }}
            </h3>
            <small>จำนวน  {{ item.amount }} KRW</small>
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: ['data'],
}
</script>

<style lang="scss" scoped>

</style>
