<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <div class="h4 mb-1">
          เปิด-ปิด ระบบธนาคาร
        </div>

        <div class="box-status">
          <div class="d-flex justify-content-between align-items-center my-50">
            <div>ฝากธนาคาร (หน้าฝาก)</div>

            <b-form-checkbox
              v-model="data.bank_status"
              name="check-button"
              switch
              inline
              @change="Submit()"
            >
              {{ data.bank_status ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
            </b-form-checkbox>
          </div>

          <div
            v-if="agent"
            class="d-flex justify-content-between align-items-center my-50"
          >
            <div>ฝาก QR Code (Cloud Pay)</div>

            <b-form-checkbox
              v-model="agent.status_dep_cloudpay"
              name="check-button"
              :unchecked-value="0"
              :value="1"
              switch
              inline
              @change="Submit()"
            >
              {{ agent.status_dep_cloudpay ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
            </b-form-checkbox>
          </div>

          <!-- <div class="d-flex justify-content-between align-items-center my-50">
            <div>ฝากด่วน</div>

            <b-form-checkbox
              v-model="data.ex_status"
              name="check-button"
              switch
              inline
              @change="Submit()"
            >
              {{ data.ex_status ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
            </b-form-checkbox>
          </div> -->

          <div class="d-flex justify-content-between align-items-center my-50">
            <div>ฝาก Truemoney Wallet</div>

            <b-form-checkbox
              v-model="data.true_status"
              name="check-button"
              switch
              inline
              @change="Submit()"
            >
              {{ data.true_status ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
            </b-form-checkbox>
          </div>
        </div>
      </b-card-body>
      <hr>

      <b-card-body>
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="mb-0">
            รายการธนาคาร
          </h4>

          <button
            class="btn btn-primary"
            @click="addBank"
          >
            <i class="far fa-plus mr-50" /> เพิ่มธนาคาร
          </button>
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(picture)="data">
          <b-avatar
            size="lg"
            :src="data.item.picture"
          />
        </template>
        <template #cell(linksms)="data">
          <p v-if="data.item.bankcode === '014'">
            {{ endpoint }}/receivesms/scb/{{ UserData.agent_id }}/{{ data.item.no }}
          </p>
          <p v-else-if="data.item.bankcode === '902'">
            {{ endpoint }}/truewallet/receive_sms/{{ UserData.agent_id }}
          </p>
        </template>
        <template #cell(bank)="data">
          <div>
            <div class="text-center">
              <img
                v-if="data.item.bank === 'ทรูมันนี่วอลเล็ท'"
                :src="`/bankIcon/${data.item.bank_path_photo ? data.item.bank_path_photo : 'true.svg'}`"
                alt="bank"
                height="25"
                width="25"
                class="rounded-circle"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              >
              <img
                v-else-if="data.item.bank === 'crypto'"
                :src="`/bankIcon/${data.item.bank_path_photo}`"
                alt="bank"
                height="25"
                width="25"
                class="rounded-circle"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              >
              <img
                v-else
                :src="`/bankIcon/${data.item.bank_path_photo ? data.item.bank_path_photo : 'dollar.png'}`"
                alt="bank"
                height="25"
                width="25"
                class="rounded-circle"
                :style="`background: ${data.item.bank_bg ? data.item.bank_bg : '#fff'};`"
              ><br>
              <small class="text-info"><u>{{ data.item.acc_no }}</u></small><br>
              <small>{{ data.item.bank }}</small>
            </div>
          </div>
        </template>
        <template #cell(acc_name)="data">
          <b-badge
            v-if="data.item.auto_slip"
            variant="info"
          >
            AutoSlip
          </b-badge>
          {{ data.item.acc_name }}
        </template>
        <template #cell(deposit_status)="data">
          <b-form-select
            v-model="data.item.deposit_status"
            :options="optionsStatus"
            @change="ChangeStatus(data.item.acc_number, data.item.deposit_status, data.item.withdraw_status)"
          />
        </template>

        <template #cell(withdraw_status)="data">
          <b-form-select
            v-model="data.item.withdraw_status"
            :options="optionsStatus"
            @change="ChangeStatus(data.item.acc_number, data.item.deposit_status, data.item.withdraw_status)"
          />
        </template>
        <template #cell(balance)="data">
          <p
            v-if="data.item.bankcode === '014'"
            style="color: green;"
          >
            {{ data.item.balance }}
          </p>
          <p
            v-else-if="data.item.balance"
            style="color: green;"
          >
            {{ Number(data.item.balance).toLocaleString() }}
          </p>
          <p
            v-else
            style="color: red;"
          >
            0
          </p>
        </template>

        <template #cell(curentime)="data">
          {{ curentime }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            id="dropdown-3"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            text="Success"
            variant="success"
          >
            <template #button-content>
              <i class="far fa-edit mr-50" /> จัดการ
            </template>
            <b-dropdown-item @click="Del(data.item.acc_number)"><i class="fas fa-trash mr-1 text-danger" /> ลบ
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      ref="my-bank"
      title="ข้อมูลธนาคาร"
      ok-title="บันทึกข้อมูล"
      ok-variant="success"
      cancel-title="ย้อนกลับ"
      cancel-variant="warning"
      size="lg"
      @ok.prevent="handleSubmit()"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="ธนาคาร">
                <validation-provider
                  #default="{ errors }"
                  name="bank"
                  rules="required"
                >
                  <v-select
                    v-model="bank.bank"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :state="errors.length > 0 ? false : null"
                    :reduce="name => name.bankcode"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="ชื่อบัญชี">
                <validation-provider
                  #default="{ errors }"
                  name="account name"
                  rules="required"
                >
                  <b-form-input
                    v-model="bank.acc_name"
                    :state="errors.length > 0 ? false : null"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="เลขที่บัญชี">
                <validation-provider
                  #default="{ errors }"
                  name="account number"
                  rules="required"
                >
                  <b-form-input
                    v-model="bank.acc_number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Auto Slip">
                <b-form-checkbox
                  v-model="bank.auto_slip"
                  :value="1"
                  :unchecked-value="0"
                >
                  ออโต้สลิป
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- <b-col md="6" /> -->

            <b-col md="6">
              <b-form-group label="บัญชีที่ ( ใส่เฉพาะ scb )">
                <b-form-input
                  v-model="bank.no"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col md="6">
              <b-form-group label="ประเภท">
                <b-form-select
                  v-model="bank.type"
                  :options="optionsType"
                />
              </b-form-group>
            </b-col> -->

            <!-- <b-col md="6">
              <b-form-group label="Username">
                <b-form-input
                  v-model="bank.username"
                  type="text"
                />
              </b-form-group>
            </b-col> -->

            <!-- <b-col md="6">
              <b-form-group label="Password">
                <b-form-input
                  v-model="bank.password"
                  type="text"
                />
              </b-form-group>
            </b-col> -->

            <!-- <b-col md="6">
              <b-form-group label="เบอร์ PromptPay">
                <b-form-input
                  v-model="bank.prompt_pay"
                  type="text"
                />
              </b-form-group>
            </b-col> -->

            <!-- <b-col md="6">
              <b-form-group label="เวลาปิดระบบฝากออโต้ (จาก)">
                <validation-provider
                  #default="{ errors }"
                  name="deposit from"
                  rules="required"
                >
                  <b-form-input
                    v-model="bank.deposit_close_from"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <!-- <b-col md="6">
              <b-form-group label="เวลาปิดระบบฝากออโต้ (ถึง)">
                <validation-provider
                  #default="{ errors }"
                  name="deposit end"
                  rules="required"
                >
                  <b-form-input
                    v-model="bank.deposit_close_end"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col md="6">
              <b-form-group label="สถานะบัญชีฝาก">
                <b-form-select
                  v-model="bank.deposit_status"
                  :options="optionsStatus"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="ประเภทบัญชี (ไทย หรือ เกาหลี)">
                <b-form-select
                  v-model="bank.kr"
                  :options="options_currency"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col md="6">
              <b-form-group label="สถานะบัญชีถอน">
                <b-form-select
                  v-model="bank.withdraw_status"
                  :options="optionsStatus"
                />
              </b-form-group>
            </b-col> -->

            <!-- <b-col md="6">
              <b-form-group label="จำนวนเงินถอนออโต้ได้ไม่เกิน(บาท/ครั้ง)">
                <validation-provider
                  #default="{ errors }"
                  name="withdraw_limit"
                  rules="required"
                >
                  <b-form-input
                    v-model="bank.withdraw_limit"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col md="6" />

            <b-col md="12">
              <b-form-textarea
                id="textarea-default"
                v-model="bank.keyword"
                placeholder="ข้อความแสดงหน้าฝากเงิน (เวลาปิดระบบฝากออโต้)"
                rows="3"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BBadge, BFormTextarea, BFormInput, BFormGroup, BForm, BRow, BCol, BModal, BCard, BTable, BAvatar, BFormSelect, BPagination, BCardBody, BDropdown, BFormCheckbox,
  BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormSelect,
    BPagination,
    BCardBody,
    BDropdown,
    BFormCheckbox,
    BDropdownItem,
    vSelect,
  },
  directives: {
    Ripple,
  },
  // computed: {
  //   agent() {
  //     return this.$store.state.app.agent
  //   }
  // },
  data() {
    return {
      agent: null,
      endpoint: localStorage.getItem('endpoint'),
      UserData: JSON.parse(localStorage.getItem('userData')),
      data: {
        bank_status: null,
        qrcode_status: null,
        ex_status: null,
        true_status: null,
      },
      bank: {
        bank: '',
        acc_name: '',
        acc_number: '',
        type: 'web',
        username: '',
        password: '',
        prompt_pay: '',
        deposit_close_from: '',
        deposit_close_end: '',
        deposit_status: 1,
        withdraw_status: 1,
        withdraw_limit: 0,
        keyword: '',
        no: '',
        auto_slip: 0,
        kr: 0,
      },
      banklist: [],
      required,
      bank_deposit: false,
      qr_code_deposit: false,
      ex_deposit: false,
      true_deposit: false,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      fields: [
        { key: 'bank', label: ' ธนาคาร' },
        { key: 'acc_name', label: 'ชื่อบัญชี' },
        { key: 'acc_number', label: 'เลขบัญชี' },
        {
          key: 'balance',
          label: 'ยอดเงินในบัญชี',
        },
        { key: 'curentime', label: 'อัพเดทเมื่อ' },
        { key: 'linksms', label: 'ลิ้ง sms' },
        // { key: 'withdraw_limit', label: 'จำนวนเงินถอนออโต้ได้ไม่เกิน(บาท/ครั้ง)' },
        { key: 'deposit_status', label: 'สถานะบัญชีฝาก' },
        // { key: 'withdraw_status', label: 'สถานะบัญชีถอน' },
        { key: 'actions', label: 'จัดการข้อมูล' },
      ],
      items: [

      ],
      optionsStatus: [
        { value: 0, text: 'ปิด' },
        { value: 1, text: 'Opened' },
      ],
      optionsType: [
        { value: 'web', text: 'Web' },
        { value: 'app', text: 'App' },
      ],
      options_currency: [
        { value: 0, text: 'ไทย' },
        { value: 1, text: 'เกาหลี' },
      ],
      curentime: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
    }
  },
  mounted() {
    this.getAgent()
    this.Get_bank()
    this.getSetting()
  },
  methods: {
    async getAgent() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log('xxx')
          this.agent = response.data

          // console.log(response.data)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          console.log(error)
        })
    },
    async getSetting() {
      await this.$http
        .get(
          '/ak/bank',
        )
        .then(response => {
          // console.log(response.data)
          this.show = false
          if (response.data.status === true) {
            this.data = response.data.data
            if (response.data.data.bankdata) {
              this.items = response.data.data.bankdata
            }
          }
        })
        .catch(error => console.log(error))
    },
    handleSubmit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$http
            .post(
              '/ak/bank/addbank',
              this.bank,
            )
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.getSetting()
              this.bank = {
                bank: '',
                acc_name: '',
                acc_number: '',
                type: 'web',
                username: '',
                password: '',
                prompt_pay: '',
                deposit_close_from: '',
                deposit_close_end: '',
                deposit_status: 1,
                withdraw_status: 1,
                withdraw_limit: 0,
                keyword: '',
              }
              // console.log(response)
              this.Success('บันทึกข้อมูลสำเร็จ')
            })
            .catch(error => console.log(error))
        }
      })
    },
    Submit() {
      this.$http
        .post(
          '/ak/bank/settings',
          { ...this.data, status_dep_cloudpay: this.agent.status_dep_cloudpay },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response)
          this.Success('บันทึกข้อมูลสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    Del(data) {
      const obj = {
        acc_number: data,
      }
      this.$http
        .post(
          '/ak/bank/deletebank',
          obj,
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.getSetting()
          this.Success('ลบข้อมูลสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    ChangeStatus(data, depstatus, withstatus) {
      const obj = {
        acc_number: data,
        deposit_status: depstatus,
        withdraw_status: withstatus,
      }
      this.$http
        .post(
          '/ak/bank/changestatus',
          obj,
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.getSetting()
          // console.log(response)
          this.Success('บันทึกข้อมูลสำเร็จ')
        })
        .catch(error => console.log(error))
    },
    Get_bank() {
      this.$http
        .get('/bank/list')
        .then(response => {
          this.banklist = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    addBank() {
      this.$refs['my-bank'].show()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.box-status {
  max-width: 280px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
