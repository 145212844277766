<template>
  <b-card
    class="card-transaction h-100"
    no-body
  >
    <b-card-header>
      <b-card-title>สรุปผลของ ยอดฝาก/ยอดถอน/โบนัส</b-card-title>
      <feather-icon
        icon="MoreVerticalIcon"
        size="18"
        class="cursor-pointer"
      />
    </b-card-header>

    <b-card-body>
      <div
        v-for="transaction in transactionData"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="transaction.icon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title mb-0">
              {{ transaction.title }}
            </h6>
            <small class="text-muted">{{ transaction.en }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          style="font-size: 1.3rem"
          :class="
            transaction.title === 'ฝากเงิน'
              ? 'text-success'
              : transaction.title === 'ถอนเงิน'
                ? 'text-danger'
                : transaction.title === 'โบนัส'
                  ? 'text-info'
                  : 'text-warning'
          "
        >
          {{
            transaction.amount
              ? transaction.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : 0
          }}
          <span style="font-size: 0.95rem">THB</span>
        </div>
      </div>
      <hr>

      <div
        v-for="transaction in transactionDataKR"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="transaction.icon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title mb-0">
              {{ transaction.title }}
            </h6>
            <small class="text-muted">{{ transaction.en }}</small>
          </b-media-body>
        </b-media>
        <div
          style="font-size: 1.3rem"
          class="font-weight-bolder"
          :class="
            transaction.title === 'ฝากเงิน'
              ? 'text-success'
              : transaction.title === 'ถอนเงิน'
                ? 'text-danger'
                : transaction.title === 'โบนัส'
                  ? 'text-info'
                  : 'text-warning'
          "
        >
          {{
            transaction.amount
              ? transaction.amount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
              : 0
          }}
          <span style="font-size: 0.95rem">KRW</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BIcon,
  BCol,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BIcon,
    BCol,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BCardText,
  },
  props: ['data'],
  data() {
    return {
      fields: [
        { key: 'acc_name', label: 'ชื่อ' },
        { key: 'status', label: 'สถานะ' },
        { key: 'bank', label: 'ธนาคาร' },
        { key: 'withdraw_stamp', label: 'สั่งถอนเมื่อ' },
        { key: 'amount', label: 'ยอดคงเหลือ' },
      ],
      items: [],
    }
  },
  computed: {
    transactionData() {
      return [
        {
          icon: 'TrendingUpIcon',
          title: 'ฝากเงิน',
          en: 'Deposit',
          amount:
            this.$props.data && this.$props.data.depSum.length
              ? this.$props.data.depSum[0].amount
              : 0,
          avatarVariant: 'light-success',
        },
        {
          icon: 'TrendingDownIcon',
          title: 'ถอนเงิน',
          en: 'Withdraw',
          amount:
            this.$props.data && this.$props.data.witSum.length
              ? this.$props.data.witSum[0].amount
              : 0,
          avatarVariant: 'light-danger',
        },
        {
          icon: 'DollarSignIcon',
          title: 'โบนัส',
          en: 'Bonus',
          amount:
            this.$props.data && this.$props.data.bonusSum.length
              ? this.$props.data.bonusSum[0].amount
              : 0,
          avatarVariant: 'light-info',
        },
        {
          icon: 'PocketIcon',
          title: 'เกม',
          en: 'Game',
          amount:
            this.$props.data && this.$props.data.gameSum.length
              ? this.$props.data.gameSum[0].amount
              : 0,
          avatarVariant: 'light-warning',
        },
      ]
    },
    transactionDataKR() {
      return [
        {
          icon: 'TrendingUpIcon',
          title: 'ฝากเงิน',
          en: 'Deposit',
          amount:
            this.$props.data && this.$props.data.depSumKR.length
              ? this.$props.data.depSumKR[0].amount
              : 0,
          avatarVariant: 'light-success',
        },
        {
          icon: 'TrendingDownIcon',
          title: 'ถอนเงิน',
          en: 'Withdraw',
          amount:
            this.$props.data && this.$props.data.witSumKR.length
              ? this.$props.data.witSumKR[0].amount
              : 0,
          avatarVariant: 'light-danger',
        },
        {
          icon: 'DollarSignIcon',
          title: 'โบนัส',
          en: 'Bonus',
          amount:
            this.$props.data && this.$props.data.bonusSumKR.length
              ? this.$props.data.bonusSumKR[0].amount
              : 0,
          avatarVariant: 'light-info',
        },
        {
          icon: 'PocketIcon',
          title: 'เกม',
          en: 'Game',
          amount:
            this.$props.data && this.$props.data.gameSumKR.length
              ? this.$props.data.gameSumKR[0].amount
              : 0,
          avatarVariant: 'light-warning',
        },
      ]
    },
  },
}
</script>

<style scoped>
.card {
  border-radius: 1rem;
}
</style>
